import {
  BrandsStrip,
  CardBlock,
  EventsGrid,
  FeaturedSection,
  FooterCTA,
  FormAttend,
  FormSponsor,
  FormContactUsV3,
  HeroBrand,
  Link,
  LinkBox,
  LogosStrip,
  Offices,
  SectionCallout,
  SectionOptions,
  SEO,
  SocialShare,
  Utils,
  ActionButton,
  ActionButtonAlt
} from "Components"
import Config from "Data"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SinglePage from "Layouts/SinglePage"
import moment from "moment"
import React from "react"
import Notifications, { notify } from "react-notify-toast"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import { Brands, Content, Gap, Partners, Wrapper } from "Styles"
import { theme } from "Theme"
import voca from "voca"




const TitlesGrid = styled.div`
    margin: 0rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    @media (min-width: ${theme.breakpoints.s}) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }
    @media (min-width: ${theme.breakpoints.m}) {
    }
    @media (min-width: ${theme.breakpoints.l}) {
      grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    }
    grid-gap: 1rem;
  `
const TitlesGridFull = styled.div`
    margin: 1rem auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    /* @media (min-width: ${theme.breakpoints.s}) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }
    @media (min-width: ${theme.breakpoints.m}) {
    }
    @media (min-width: ${theme.breakpoints.l}) {
      grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    } */
    grid-gap: 1rem;
  `
const TitleBlock = styled.div`
    a {
      width: 100%;
      transition: 0.3s;
      &:hover,
      &:focus {
        transform: scale(1.02);
        box-shadow: ${theme.shadow.feature.small.hover};
      }
    }
  `

const TitlesTitle = styled.h2`
    color: inherit;
    margin: 0;
    p {
      margin: 0;
    }
  `

const BrandedColor = styled.span`
    color: ${({ color }) => (color ? `${Utils.BrandColor(color)}` : `${theme.colors.primary.base}`)};
  `

const TitlesDesc = styled.p`
    color: inherit;
    font-size: ${theme.fontSize.small};
    margin: 0;
  `

const ActionsBlock = styled.div`
    text-align: center;
    background: #fff;
    border-radius: ${theme.borderRadius.ui};
    box-shadow: ${theme.shadow.feature.small.default};
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      display: grid;
      align-content: center;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
    transition: 0.3s;
    &:hover,
    &:focus {
      transform: scale(1.04);
      box-shadow: ${theme.shadow.feature.small.hover};
    }
  `

const Grid = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.layout.base};
  `

const SectionContent = styled.div`
    direction: ${(props) => (props.dir === "rtl" ? "rtl;" : "ltr;")};
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      grid-template-columns: 2fr 33%;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
    grid-gap: 1rem;
    margin: 0 auto;
  `

const BrandDescription = styled.div`
    direction: ltr;
    text-align: ${(props) => (props.dir === "rtl" ? "right;" : "left;")};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 2.4rem;
      margin-bottom: 2.4rem;
    }
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1.2rem;
    }
    padding: 2rem 1rem;
    margin: 4rem auto 0;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      max-width: 60%;
      margin: 4rem 0;
      padding: 0 0;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
  `

const BrandsGrid = styled.div`
    margin: 2rem auto 6rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${theme.breakpoints.s}) {
    }
    @media (min-width: ${theme.breakpoints.m}) {
      grid-template-columns: 1fr;
      margin: 4rem auto;
    }
    @media (min-width: ${theme.breakpoints.l}) {
      grid-template-columns: 1fr 1fr;
    }
    grid-gap: 1rem;
  `

const StyledNotificationButton = styled((props) => <Link {...props} />)`
    color: ${theme.colors.white.base};
    font-size: ${theme.fontSize.small};
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
    display: block;
    border-radius: ${theme.borderRadius.elements};
    padding: 12px 18px;
    background: ${theme.colors.primary.base};
    border-width: 1px;
    border-color: ${theme.colors.primary.base};
    border-style: solid;
    width: 100%;
    text-align: inherit;
    margin: 6px;
    span {
      padding: 1rem !important;
    }
    &:hover,
    &:focus {
      color: ${theme.colors.white.base};
      background: ${theme.colors.primary.dark};
      border-color: ${theme.colors.primary.base};
      outline: 0;
      box-shadow: ${theme.shadow.feature.small.hover};
    }
    @media (min-width: ${theme.breakpoints.m}) {
      margin: 0 0 0 6px;
      padding: 6px 18px;
      font-size: ${theme.fontSize.smallish};
      width: auto;
      display: inline-block;
      text-align: left;
    }
    @media (min-width: ${theme.breakpoints.l}) {
    }
  `
const SectionHeaderTitle = styled.span`
   font-weight: 700;
    font-size: 1.9rem;
    text-align: center;
    border-bottom: 1px solid ${Utils.BrandColor(Config.brand)};
    @media (min-width: ${theme.breakpoints.l}) {
      font-size: 2.9rem;
    }
  `
const SectionHeader = styled.div`
  margin-bottom :3rem;
  text-align :center;
`
const SectionContentGrid = styled.div`
      display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 2rem;
`
const SectionCard = styled.div`
    /* background: #f2f2f2; */
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 1rem;
    min-width :24%;
    font-weight :bold;

`

const SectionAction = styled((props) => <Link {...props} />)`
    border: 2px solid ${Utils.BrandColor(Config.brand)};
    padding: 12px 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
  `
const OptionSection = styled.div`
  /* width:260px ; */
  border: 2px solid ${Utils.BrandColor(Config.brand)};
  border-radius: 12px;
`
const OptionSectionGala = styled.div`
  /* width:260px ; */
  border: 2px solid ${Utils.BrandColor(Config.brand)};
  border-radius: 12px;
`
const OptionSectionHeader = styled.div`
    background: ${Utils.BrandColor(Config.brand)};
    color: ${theme.colors.white.base};
    font-weight: 500;
    font-size: 1.2rem;
    padding: 6px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    ${({ align }) => align && 'text-align:' + align}
    `

const OptionEventContainer = styled.div`
  min-height:${({ height }) => (height ? height + 'px' : 'auto')};
  margin: 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: ${({ direction }) => direction == 'row' ? 'repeat(auto-fill, minmax(100%, 1fr))' : 'repeat(auto-fill, minmax(22%, 1fr))'};
    margin: 2rem 1rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: ${({ direction }) => direction == 'row' ? 'repeat(auto-fill, minmax(100%, 1fr))' : 'repeat(auto-fill, minmax(22%, 1fr))'};
  }
  grid-gap: 16px;
  ${({ direction }) => direction == 'row' && 'align-content: space-around;'}
`
const OptionEventCard = styled.div`
    position :relative;
    min-height:100px ;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    border-radius: 10px;
    transition : all 4s ease-in;
    /* border: 1px dashed #ababab; */
    box-shadow: 1px 1px 15px -13px #000000;
    &:hover,
    &:focus {
      /* border: 2px solid #ababab; */
    }
    background :white;
    /* &:before {
      content: "";
      height: 20px;
      width: 20px;
      background: #f6f6f6;
      position: absolute;
      left: -10px;
      top: 34px;
      border-radius: 50%;
    }
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      background: #f6f6f6;
      position: absolute;
      right: -10px;
      top: 34px;
      border-radius: 50%;
    } */
    `


const OptionEventTitle = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 2px dashed #c9c9c9;
  padding-bottom: 8px;
`
const OptionEventDate = styled.div`
`


const OptionEventCard1 = styled.div`
    position :relative;
    min-height:135px ;
    overflow:hidden ;
    /* padding: 8px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 1rem; */
    border-radius: 10px;
    transition : all 50ms ease-in;
    /* border: 1px dashed #ababab; */
    box-shadow: 1px 1px 15px -13px #000000;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    &:hover,
    &:focus {
      /* border: 2px solid #ababab; */
      box-shadow: 0px 15px 25px rgb(0 0 0 / 20%);
      transform: scale(1.04);
    }
    background :white;
   
    `

const OptionEventTitle1 = styled.div`
width: 100%;
text-align: center;
padding-bottom: 8px;
padding-left: 5px;
min-height: 66%;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
padding-right: 5px;

border-radius: 12px;
font-weight:500 ;
/* border-top: 2px solid ${Utils.BrandColor(Config.brand)};
border-left: 2px solid ${Utils.BrandColor(Config.brand)};
border-right: 2px solid ${Utils.BrandColor(Config.brand)}; */
border-bottom-left-radius:0px ;
   border-bottom-right-radius:0px ;
`
const OptionEventDate1 = styled.div`
width: 100%;
min-height: 45px;
background: ${Utils.BrandColor(Config.brand)};
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
color: #fff;
font-weight: 700;
font-size: 1.2rem;
`

const OptionEventCard2 = styled.div`
    position :relative;
    min-height:106px ;
    overflow:hidden ;
    /* padding: 8px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 1rem; */
    border-radius: 10px;
    transition : all 50ms ease-in;
    /* border: 1px dashed #ababab; */
    box-shadow: 1px 1px 15px -13px #000000;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    &:hover,
    &:focus {
      /* border: 2px solid #ababab; */
      box-shadow: 0px 15px 25px rgb(0 0 0 / 20%);
      transform: scale(1.04);
    }
    background :white;
 
    `

const OptionEventTitle2 = styled.div`
width: 100%;
text-align: center;
padding-bottom: 8px;
padding-left: 5px;
min-height: 57%;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
padding-right: 5px;

border-radius: 12px;
font-weight:500 ;
/* border-top: 2px solid ${Utils.BrandColor(Config.brand)};
border-left: 2px solid ${Utils.BrandColor(Config.brand)};
border-right: 2px solid ${Utils.BrandColor(Config.brand)}; */
border-bottom-left-radius:0px ;
border-bottom-right-radius:0px ;
`
const OptionEventDate2 = styled.div`
width: 100%;
min-height: 45px;
background: ${Utils.BrandColor(Config.brand)};
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
color: #fff;
font-weight: 700;
font-size: 1.2rem;
`
const DottedStrip = styled.div`
  /* border-bottom: 2px dashed  ${Utils.BrandColor(Config.brand)}90; */
  width:100% ;
  position:relative ;
 /* &:before {
      content: "";
      height: 20px;
      width: 20px;
      background: #f6f6f6;
      position: absolute;
      left: -10px;
      top: -9px;
      border-radius: 50%;
      border: 2px solid ${Utils.BrandColor(Config.brand)};
      box-shadow: #424242e6 0px 0px 3px 0px inset;
    }
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      background: #f6f6f6;
      position: absolute;
      right: -10px;
      top: -9px;
      border-radius: 50%;
      border: 2px solid ${Utils.BrandColor(Config.brand)};
      box-shadow: #424242e6 0px 0px 3px 0px inset;
    } */
`


const OptionSectionContainer = styled.div`
   margin: 1rem auto;
  display: flex;
  /* flex-wrap: wrap; */
  flex-wrap: nowrap;
    justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    /* grid-template-columns: repeat(auto-fill, minmax(22%, 1fr)); */
    margin: 1rem auto;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    /* grid-template-columns: repeat(auto-fill, minmax(22%, 1fr)); */
  }
  grid-gap: 2rem;
  flex-direction:column ;
`
const PackageSection = styled.div`
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: ${({ columns }) => columns ? 'repeat(auto-fill, minmax(' + ((100 / columns) - 2.5) + '%, 1fr))' : 'repeat(auto-fill, minmax(25%, 1fr))'} ;
    margin: 1rem auto;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: ${({ columns }) => columns ? 'repeat(auto-fill, minmax(' + ((100 / columns) - 2.5) + '%, 1fr))' : 'repeat(auto-fill, minmax(25%, 1fr))'} ;
  }
  grid-gap: ${({ gap }) => gap ? gap + 'rem' : '3rem'};
`

const PackageCard = styled.div`
  border:3px solid;
  border-color : ${(props) => props.highlight ? (Utils.BrandColor(Config.brand) + '85') : " #d5d5d5"};
  border-radius : 1rem;
  position : relative;
  margin-top : 2rem;
`

const PackageTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 124px;
    border-bottom: 3px solid;
    border-color : ${(props) => props.highlight ? (Utils.BrandColor(Config.brand) + '85') : " #d5d5d5"};
    span {
      color :${Utils.BrandColor(Config.brand)};
      font-weight: 600;
      font-size: 1.2rem;
      border-bottom: 2px solid #5e5d5d;
    }
`

const PackageOptionBox = styled.div`
    padding: 1rem 1rem 1rem 2rem;
`
const PackageOptionList = styled.ul`
list-style: square;
`
const PackageOption = styled.li`
    margin-bottom: 2rem;
    b{
      color :${Utils.BrandColor(Config.brand)}90;
    }
  
`
const EventSection = styled.div`
  
`
const CardTag = styled.div`
    border : 2px solid;
    border-color : #fff;
    position: absolute;
    right: -20px;
    top: -38px;
    display: flex;
    height: 80px;
    width: 80px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    padding: 1rem;
    background:${(Utils.BrandColor(Config.brand))};
`
const CardTagTitle = styled.span`
    font-weight: 700;
    text-align: center;
    color: ${theme.colors.white.base};
    font-size: .85rem;
`

const EventDivider = styled.div`
border-bottom :1px solid #e5e5e5;
`


const TestimonialContainer = styled.div`
  padding: 1rem 0rem;
  display: grid;
  gap: 2rem;
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 1rem 1rem;
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 1rem 2rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    padding: 1rem 3rem;
  }
`
const SideStrip = styled.div`
  background: ${(Utils.BrandColor(Config.brand))};
    width: 7px;
    height: 70%;
    position: absolute;
    ${({ direction }) => direction == 'rtl' ? 'right: -3px' : 'left: -3px'};
    border-radius: 5px;
`
const TestimonialItem = styled.div`
  display:flex ;
  justify-content:${({ direction }) => direction == 'rtl' ? 'flex-end' : 'flex-start'} ;
`
const Testimonialcontent = styled.div`
  width: 100%;
    display: grid;
    gap: 1rem;
    align-items: center;
    /* border: 2px solid ${(Utils.BrandColor(Config.brand))}5c; */
    padding: 2rem 2rem;
    border-radius: 16px;
    box-shadow: 0px 15px 25px rgb(0 0 0 / 20%);
    position: relative ;
    @media (min-width: ${theme.breakpoints.s}) {
    
  }
  @media (min-width: ${theme.breakpoints.m}) {
   
  }
  @media (min-width: ${theme.breakpoints.l}) {
    width: 70%;
  }

`
const TestimonialText = styled.div`
    /* text-align: justify; */
    font-weight: 500;
    span{
      font-weight: 900;
    }
`
const TestimonialClient = styled.div`
  text-align: right;
  font-weight: 700;
`
const formateDate = (dateStr = null) => {
  let r = "NA"
  try {
    if (moment(dateStr).isValid()) {
      let day = moment(dateStr).format("D")
      let month = moment(dateStr).format("MMM")
      r = month + " " + day;
    }
  } catch (e) {

  }
  return r;
}

class Options extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    }
  }

  componentDidMount() {
    let CXOsyncColors = { background: `${theme.colors.white.base}`, text: `${theme.colors.primary.base}` }
    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    })
    // notify.show(
    //     <div>
    //         Novel Coronavirus (COVID-19) Update{" "}
    //         <StyledNotificationButton to={"/resources/covid19"} target={"_blank"} track={"COVID-19 Link"}>
    //             read more.
    //         </StyledNotificationButton>
    //     </div>,
    //     "custom",
    //     -1,
    //     CXOsyncColors
    // )
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault()
    if (this.state.currentModal) {
      this.handleModalCloseRequest()
      return
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    })
  }

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    })
  }

  handleOnAfterOpenModal = () => { }


  render() {
    const { data } = this.props
    // console.log(data);
    const hero = data.hero.edges[0].node
    const backgrounds = data.backgrounds
    const featured = data.featured.edges[0].node
    const featuredAlt = data.featuredAlt.edges[0].node
    const triangle = data.triangle
    const device = data.device
    const devices = data.devices
    const mapSection = data.about.mapSection
    const offices = data.offices.edges

    const cio = data.cio.edges
    const ciso = data.ciso.edges
    const cmo = data.cmo.edges
    const cfo = data.cfo.edges
    const eps_logo = data.eps_logo
    const eps_bg = data.eps_bg

    // console.log({ data })
    let allEventsData = [].concat(
      data.events2020.edges,
      data.events2021.edges,
      data.events2022.edges,
      data.events2023.edges
    )
    let allEvents = allEventsData.sort((a, b) => (a.node.date > b.node.date ? 1 : -1))

    let futureEvents = allEvents.filter((event) => {
      return !moment(event.node.date).isBefore(moment(), "day")
    })
    futureEvents.sort((a, b) => (a.node.date > b.node.date ? 1 : -1))

    let allGalaEvents = futureEvents.filter((obj) => voca.slugify(obj.node.eventType) == 'gala');
    let allConferenceEvents = futureEvents.filter((obj) => voca.slugify(obj.node.eventType) == 'conference');


    let allEMEAEvents = allConferenceEvents?.filter((obj) => voca.slugify(obj.node.region) == 'emea');
    let allAPACEvents = allConferenceEvents?.filter((obj) => voca.slugify(obj.node.region) == 'apac');
    let allLATAMEvents = allConferenceEvents?.filter((obj) => voca.slugify(obj.node.region) == 'latam');
    let allNorthAmericaEvents = allConferenceEvents?.filter((obj) => voca.slugify(obj.node.region) == 'north-america');
    let allAmericasEvents = allConferenceEvents?.filter((obj) => voca.slugify(obj.node.region) == 'americas');


    const partners = data.partners_data
    const brands = data.brands.edges
    const { currentModal } = this.state

    return (
      <SinglePage
        locale={"en-us"}
        region={"us"}
        sticky={false}
        transparentHeader={true}
        transparentFooter={false}
        mode={"transparent"}
        path={this.props.path}>
        <SEO
          pathname="/"
          title={`CXO Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `We host live and virtual events to gather CXOs from the world's largest corporations and brands. From our global conferences, private dinners and virtual events; CXOs gather to discuss, debate and challenge issues and strategies - a brain-trust built on thought-leading content` ||
            ""
          }
          keywords={"pageKeywords"}
          banner={hero.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
          events={futureEvents}
          indexed={false}
        />
        <SocialShare
          pathname="/"
          title={`CXO Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `We host live and virtual events to gather CXOs from the world's largest corporations and brands. From our global conferences, private dinners and virtual events; CXOs gather to discuss, debate and challenge issues and strategies - a brain-trust built on thought-leading content` ||
            ""
          }
          banner={hero.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
        />

        <Notifications options={{ zIndex: 9, top: "80px" }} />
        <HeroBrand
          titles={false}
          height={100}
          justify={"end"}
          option_page={true}
          title={"YOU'VE GOT NEEDS,"}
          title_1={"WE'VE GOT OPTIONS!"}
          subtitle={`CMO sponsorship packages offer valuable access to the Office of the CMO. Sponsors choose levels of access that that range from 'Exhibiting' to engaging as 'Premier + Content'. Take the plunge and customize your access today.`}
          // color={'#171717'}
          colorPlain={'#171717'}
          countries={false}
          image={featuredAlt.childImageSharp.gatsbyImageData || ""}
          track={"Hero"}
          // cta_url1={"/events"}
          // cta_url1_text={'Upcoming Events'}
          options_cta_action1_text={"Contact Us"}
          options_cta_action1={this.toggleModal("modal_contactus", {
            shareEvent: null,
            showEvents: true,
          })}
        />
        {/* <Gap /> */}

        {/* <SectionCallout
          small={true}
          dark={false}
          grid={true}
          page={this.props}
          background={triangle.edges[0].node.childImageSharp.gatsbyImageData}> */}




        {/* <SectionTweets title={`What CXOsync Leaders Are Saying`} data={tweetsSection} tweets={tweets} />
                    <Gap /> */}
        {/* </SectionCallout> */}

        {/* <SectionCallout
                    size={"big"}
                    dark={true}
                    image={device.edges[0].node.childImageSharp.gatsbyImageData}
                    logo={devices.edges[0].node.childImageSharp.gatsbyImageData}
                    logoSize={"280px"}
                    title={`The CXOsync Event App`}
                    subtitle={`Download the APP, take surveys and visit booths during networking sessions to get points and WIN TECH CXOsync events.`}
                    cta_url1_text={"Visit Site"}
                    cta_url1={"https://www.audienceapp.com/"}
                    track={"Callout Top"}
                />
                <Gap /> */}
        {
          (allGalaEvents.length > 0 || allConferenceEvents.length > 0) && (<SectionOptions size={"medium"} track={"events_menu"}>
            <SectionHeader id="section-upcoming-events">
              <SectionHeaderTitle>Upcoming Events</SectionHeaderTitle>
            </SectionHeader>
            <SectionContentGrid>
              {
                (allConferenceEvents.length > 0) && (<SectionCard>
                  <SectionAction section_ref={true} key={1} to={"#cmo-section"} track={"CMO Link"}>CMO.org</SectionAction>
                  <div>Day</div>
                </SectionCard>)
              }

              {
                (allGalaEvents.length > 0) && (<SectionCard>
                  <SectionAction section_ref={true} key={2} to={"#cmogala-section"} track={"CMOgala Link"} >CMOgala</SectionAction>
                  <div>Day / Evening</div>
                </SectionCard>)
              }

            </SectionContentGrid>
          </SectionOptions>)
        }

        <EventSection id="section-sponsorship-options">
          {
            (allGalaEvents.length > 0) && (<> <SectionOptions backgroundColor={'#f6f6f6'} size={"medium"} track={"events_menu"}>
              <SectionHeader id="cmogala-section">
                <SectionHeaderTitle><BrandedColor>CMO</BrandedColor>gala</SectionHeaderTitle>
              </SectionHeader>
              <OptionSectionGala>
                <OptionSectionHeader><span>CMO GALA EVENTS</span></OptionSectionHeader>
                <OptionEventContainer direction="column">
                  {
                    allGalaEvents.map((event, index) => {
                      return (
                        <OptionEventCard1 key={index}>
                          <OptionEventTitle1><span>{event?.node?.eventTitle}</span></OptionEventTitle1>
                          <DottedStrip />
                          <OptionEventDate1><span>{event?.node?.date ? formateDate(event.node.date) : ""}</span></OptionEventDate1>
                        </OptionEventCard1>);
                    })
                  }

                </OptionEventContainer>
              </OptionSectionGala>
              <Gap />
              <PackageSection columns="3" gap="2">
                {
                  Config.packages?.cmo?.gala && (
                    Config.packages?.cmo?.gala?.map((data, index) => {
                      return (
                        <PackageCard highlight={data.highlight}>
                          {
                            data?.tag && data?.tag_line && (
                              <CardTag highlight={data.highlight}>
                                <CardTagTitle>{data.tag_line}</CardTagTitle>
                              </CardTag>
                            )
                          }
                          <PackageTitle highlight={data.highlight}><span>{data?.title || ""}</span></PackageTitle>
                          <PackageOptionBox>
                            <PackageOptionList>
                              {
                                data?.options?.map((option, i) => {
                                  return (
                                    <PackageOption dangerouslySetInnerHTML={{ __html: option?.text?.replace(/\n/g, '<br />') }}></PackageOption>
                                  )
                                })
                              }

                            </PackageOptionList>

                          </PackageOptionBox>
                        </PackageCard>
                      )
                    })
                  )
                }
              </PackageSection>
              <Gap />
            </SectionOptions>
              <EventDivider /></>)
          }

          {
            (allConferenceEvents.length > 0) && (<SectionOptions backgroundColor={'#f6f6f6'} size={"medium"} track={"events_menu"}>
              <SectionHeader id="cmo-section">
                <SectionHeaderTitle><BrandedColor>CMO</BrandedColor>.org</SectionHeaderTitle>
              </SectionHeader>
              <OptionSectionContainer >
                {
                  (allAPACEvents.length > 0) && (
                    <OptionSection key={"apac_1"}>
                      <OptionSectionHeader align="center"><span>APAC</span></OptionSectionHeader>
                      <OptionEventContainer direction="column">
                        {
                          allAPACEvents.map((e, index) => {
                            return (
                              <OptionEventCard2 key={index}>
                                <OptionEventTitle2><span>{e?.node?.stateCity}</span></OptionEventTitle2>
                                <DottedStrip />
                                <OptionEventDate2><span>{e?.node?.date ? formateDate(e.node.date) : ""}</span></OptionEventDate2>
                              </OptionEventCard2>)
                          })
                        }
                      </OptionEventContainer>
                    </OptionSection>
                  )
                }
                {
                  (allLATAMEvents.length > 0) && (
                    <OptionSection key={"latam_1"}>
                      <OptionSectionHeader align="center"><span>LATAM</span></OptionSectionHeader>
                      <OptionEventContainer direction="column">
                        {
                          allLATAMEvents.map((e, index) => {
                            return (
                              <OptionEventCard2 key={index}>
                                <OptionEventTitle2><span>{e?.node?.stateCity}</span></OptionEventTitle2>
                                <DottedStrip />
                                <OptionEventDate2><span>{e?.node?.date ? formateDate(e.node.date) : ""}</span></OptionEventDate2>
                              </OptionEventCard2>)
                          })
                        }
                      </OptionEventContainer>
                    </OptionSection>
                  )
                }
                {
                  (allNorthAmericaEvents.length > 0) && (
                    <OptionSection key={"northamerica_1"}>
                      <OptionSectionHeader align="center"><span>North America</span></OptionSectionHeader>
                      <OptionEventContainer direction="column">
                        {
                          allNorthAmericaEvents.map((e, index) => {
                            return (
                              <OptionEventCard2 key={index}>
                                <OptionEventTitle2><span>{e?.node?.stateCity}</span></OptionEventTitle2>
                                <DottedStrip />
                                <OptionEventDate2><span>{e?.node?.date ? formateDate(e.node.date) : ""}</span></OptionEventDate2>
                              </OptionEventCard2>)
                          })
                        }
                      </OptionEventContainer>
                    </OptionSection>
                  )
                }
                {
                  (allEMEAEvents.length > 0) && (
                    <OptionSection key={"emea_1"}>
                      <OptionSectionHeader align="center"><span>EMEA</span></OptionSectionHeader>
                      <OptionEventContainer direction="column">
                        {
                          allEMEAEvents.map((e, index) => {
                            return (
                              <OptionEventCard2 key={index}>
                                <OptionEventTitle2><span>{e?.node?.stateCity}</span></OptionEventTitle2>
                                <DottedStrip />
                                <OptionEventDate2><span>{e?.node?.date ? formateDate(e.node.date) : ""}</span></OptionEventDate2>
                              </OptionEventCard2>)
                          })
                        }
                      </OptionEventContainer>
                    </OptionSection>
                  )
                }
                {
                  (allAmericasEvents.length > 0) && (
                    <OptionSection key={"americas_1"}>
                      <OptionSectionHeader align="center"><span>Americas</span></OptionSectionHeader>
                      <OptionEventContainer direction="column">
                        {
                          allAPACEvents.map((e, index) => {
                            return (
                              <OptionEventCard2 key={index}>
                                <OptionEventTitle2><span>{e?.node?.stateCity}</span></OptionEventTitle2>
                                <DottedStrip />
                                <OptionEventDate2><span>{e?.node?.date ? formateDate(e.node.date) : ""}</span></OptionEventDate2>
                              </OptionEventCard2>)
                          })
                        }
                      </OptionEventContainer>
                    </OptionSection>
                  )
                }
              </OptionSectionContainer>
              <Gap />
              <PackageSection columns="5" gap="1.2">
                {
                  Config.packages?.cmo?.cmo && (
                    Config.packages?.cmo?.cmo?.map((data, index) => {
                      return (
                        <PackageCard highlight={data.highlight}>
                          {
                            data?.tag && data?.tag_line && (
                              <CardTag highlight={data.highlight}>
                                <CardTagTitle>{data.tag_line}</CardTagTitle>
                              </CardTag>
                            )
                          }
                          <PackageTitle highlight={data.highlight}><span>{data?.title || ""}</span></PackageTitle>
                          <PackageOptionBox>
                            <PackageOptionList>
                              {
                                data?.options?.map((option, i) => {
                                  return (
                                    <PackageOption dangerouslySetInnerHTML={{ __html: option?.text?.replace(/\n/g, '<br />') }}></PackageOption>
                                  )
                                })
                              }
                            </PackageOptionList>
                          </PackageOptionBox>
                        </PackageCard>
                      )
                    })
                  )
                }
              </PackageSection>
              <Gap />
            </SectionOptions>)
          }

        </EventSection>
        <SectionOptions backgroundColor={'#ffffff'} size={"medium"} track={"events_menu"}>
          <SectionHeader >
            <SectionHeaderTitle><BrandedColor>Words</BrandedColor>&nbsp;that Matter</SectionHeaderTitle>
          </SectionHeader>
          <TestimonialContainer>
            {
              Config.testimonials?.cio?.map((testimonial, index) => (
                <TestimonialItem key={index} direction={index % 2 == 0 ? 'ltr' : 'rtl'}>
                  <Testimonialcontent>
                    <SideStrip direction={index % 2 == 0 ? 'ltr' : 'rtl'} />
                    <TestimonialText>
                      <span>"</span>{testimonial.content}<span>"</span></TestimonialText>
                    <TestimonialClient> - {testimonial.company}</TestimonialClient>
                  </Testimonialcontent>
                </TestimonialItem>
              ))
            }

          </TestimonialContainer>
        </SectionOptions>
        <FooterCTA
          dark={false}
          title="Got questions?"
          subtitle={`Get in touch directly by sending us an email at ${Config.contact.email} or give us a call:`}
          cta_url1={`tel:${Config.contact.phone.us}`}
          cta_url1_text={`${Config.contact.phone.us}`}
          cta_url2={`tel:${Config.contact.phone.uk}`}
          cta_url2_text={`${Config.contact.phone.uk}`}
          page={this.props}
        />



        <FooterCTA
          dark={true}
          title={`Interested in attending one of our upcoming events?`}
          subtitle={`Find your seat at the table in a city near you`}
          cta_url1_text={`Events Calendar`}
          cta_url1={"/events/"}
          track={"Footer CTA"}
        />
        <FormContactUsV3
          title={`Have a question for the ${Config.brand} team?`}
          subtitle="Call us or submit below:"
          isOpen={currentModal === "modal_contactus"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_contactus")}
        />
        <FormAttend
          events={futureEvents}
          showEvents={this.state.showEvents}
          isOpen={currentModal === "modal_attend"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: false,
          })}
        />

        <FormSponsor
          events={futureEvents}
          showEvents={this.state.showEvents}
          options={false}
          optionsLink={false}
          isOpen={currentModal === "modal_sponsor"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: false,
          })}
        />
      </SinglePage>
    )
  }
}

export default Options

export const query = graphql`
    query OptionsUSQuery {
      hero: allGoogleSheetsHomepage(filter: { block: { eq: "Hero" } }) {
        edges {
          node {
            id
            block
            heroImage
            title
            subtitle
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 2880, placeholder: BLURRED)
              }
            }
          }
        }
      }
      backgrounds: allFile(filter: { sourceInstanceName: { eq: "backgrounds" } }) {
        nodes {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
      featuredAlt: allFile(filter: {name: {glob: "cmo-options-page-header"}}) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 1280, placeholder: BLURRED)
            }
          }
        }
      }
      featured: allGoogleSheetsHomepage(filter: { block: { eq: "Featured" } }) {
        edges {
          node {
            heroImage
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 1280, placeholder: BLURRED)
              }
            }
          }
        }
      }
      brands: allGoogleSheetsBrands {
        edges {
          node {
            name
            website
            logo
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 800, placeholder: BLURRED)
              }
            }
          }
        }
      }
      triangle: allFile(filter: { name: { eq: "triangle" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      device: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "audience-app-hero2" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      devices: allFile(filter: { extension: { regex: "/(png)/" }, name: { eq: "audience-app" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      eps_logo: allFile(filter: { extension: { regex: "/(png)/" }, name: { eq: "epssimpledark" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      eps_bg: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "eps" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      calloutTop: allGoogleSheetsHomepage(filter: { block: { eq: "CalloutTop" } }) {
        edges {
          node {
            id
            block
            heroImage
            title
            subtitle
          }
        }
      }
      tweetsSection: allGoogleSheetsHomepage(filter: { block: { eq: "Tweets" } }) {
        edges {
          node {
            id
            block
            title
            subtitle
          }
        }
      }
      tweets: allGoogleSheetsTweets {
        edges {
          node {
            id
            tweetId
          }
        }
      }
      cio: allGoogleSheetsBrands(filter: { audience: { eq: "CIO" } }) {
        edges {
          node {
            name
            audience
            title
            subtitle
            website
            logo
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
      ciso: allGoogleSheetsBrands(filter: { audience: { eq: "CISO" } }) {
        edges {
          node {
            name
            audience
            title
            subtitle
            website
            logo
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
      cfo: allGoogleSheetsBrands(filter: { audience: { eq: "CFO" } }) {
        edges {
          node {
            name
            audience
            title
            subtitle
            website
            logo
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
      cmo: allGoogleSheetsBrands(filter: { audience: { eq: "CMO" } }) {
        edges {
          node {
            name
            audience
            title
            subtitle
            website
            logo
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
        }
      }
      partners_data: allGoogleSheetsPartnersData(limit: 6, sort: { fields: name }, filter: { logo: { ne: "logo" } }) {
        edges {
          node {
            category
            city
            name
            logo
            website
            email
            description
            childrenFile {
              childImageSharp {
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
            }
          }
        }
      }
      about: aboutJson {
        mapSection {
          title
          copy
          video
          cta_text
          cta_link
          cta_text_1
          cta_link_1
          image {
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
      offices: allGoogleSheetsOffices {
        edges {
          node {
            id
            name
            type
            flag
            location
            city
            address
            latitude
            longitude
          }
        }
      }
      events2020: allGoogleSheetOverview2020(filter: { calendar: { eq: true }, eventType: {in: [ "conference", "gala", "n-Gala", "d-Gala"]} }, sort: { order: DESC, fields: date }) {
        totalCount
        edges {
          node {
            id
            brand
            region
            country
            events
            eventTitle
            eventType
            eventSubType
            eventTime
            audience
            industry
            panel
            stateCity
            date
            eventSite
            twitter
            image
            webinarLink
          }
        }
      }
      events2021: allGoogleSheetOverview2021(filter: { calendar: { eq: true }, eventType: {in: [ "conference", "gala", "n-Gala", "d-Gala"]}  }, sort: { order: DESC, fields: date }) {
        totalCount
        edges {
          node {
            id
            brand
            region
            country
            events
            eventTitle
            eventType
            eventSubType
            eventTime
            audience
            industry
            panel
            stateCity
            date
            eventSite
            twitter
            image
            webinarLink
          }
        }
      }
      events2022: allGoogleSheetOverview2022(filter: { calendar: { eq: true } , eventType: {in: [ "conference", "gala", "n-Gala", "d-Gala"]} }, sort: { order: DESC, fields: date }) {
        totalCount
        edges {
          node {
            id
            brand
            region
            country
            events
            eventTitle
            eventType
            eventSubType
            eventTime
            audience
            industry
            panel
            stateCity
            date
            eventSite
            twitter
            image
            webinarLink
          }
        }
      }
      events2023: allGoogleSheetOverview2023(filter: { calendar: { eq: true } , eventType: {in: [ "conference", "gala", "n-Gala", "d-Gala"]} }, sort: { order: DESC, fields: date }) {
        totalCount
        edges {
          node {
            id
            brand
            region
            country
            events
            eventTitle
            eventType
            eventSubType
            eventTime
            audience
            industry
            panel
            stateCity
            date
            eventSite
            twitter
            image
            webinarLink
          }
        }
      }
      events2024: allGoogleSheetOverview2024(filter: { calendar: { eq: true } , eventType: {in: [ "conference", "gala", "n-Gala", "d-Gala"]} }, sort: { order: DESC, fields: date }) {
        totalCount
        edges {
          node {
            id
            brand
            region
            country
            events
            eventTitle
            eventType
            eventSubType
            eventTime
            audience
            industry
            stateCity
            date
            eventSite
            twitter
            image
            webinarLink
          }
        }
      }
    }
  `
